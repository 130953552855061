import React, { useEffect } from "react"
import { Link, graphql } from "gatsby"
import styles from "@one-builder/gatsby-theme-ob-master/src/styles/global.module.scss"

// Components
import Layout from "../components/layout/layout"
import OfferForm from "../components/offer-form"
import MarkdownContent from "@one-builder/gatsby-theme-ob-master/src/components/content"
import { useGetTokens } from "@one-builder/gatsby-theme-ob-master/src/hooks/useGetTokens"
import convertTokens from "@one-builder/gatsby-theme-ob-master/src/helpers/convertTokens"
import { Parallax } from "react-parallax"
import Image from "@one-builder/gatsby-theme-ob-master/src/components/common/image"
import setSeoData from "@one-builder/gatsby-theme-ob-master/src/helpers/setSeoData"

export default ({ data, location }) => {
  useEffect(() => {
    let body = document.querySelector("body")
    if (body && !body.classList.contains("covid-landing-page")) {
      body.classList.add("covid-landing-page")
    } else {
      body.classList.remove("covid-landing-page")
    }
  }, [])

  // Get contact content from markdown
  const { special } = data

  // Get metadata
  const metadata = useGetTokens()

  let seo_data = setSeoData(special.frontmatter)

  return (
    <Layout location={location} is_landing={true} seo_data={seo_data}>
      <Parallax
        bgImage={"/images/handshake.jpg"}
        strength={-200}
        id={styles.bannerBg}
      >
        <section
          id="covid-row1"
          className={`${styles.covidRow} ${styles.covidRow1}`}
        >
          <div className={styles.container}>
            <MarkdownContent
              className={`homecontent`}
              content={convertTokens(
                special.frontmatter.contentSection1,
                metadata
              )}
            />
          </div>
        </section>
      </Parallax>

      <section
        id="covid-row2"
        className={`${styles.covidRow} ${styles.covidRow2}`}
      >
        <div className={styles.container}>
          <MarkdownContent
            className={`homecontent`}
            content={convertTokens(
              special.frontmatter.contentSection2,
              metadata
            )}
          />
        </div>
        <img
          id={styles.constructionMan}
          src="/images/construction-man.svg"
          alt="Digital marketing for construction businesses"
        />
      </section>

      <section
        id="covid-row3"
        className={`${styles.covidRow} ${styles.covidRow3}`}
      >
        <div className={styles.container}>
          <MarkdownContent
            className={`homecontent`}
            content={convertTokens(
              special.frontmatter.contentSection3,
              metadata
            )}
          />
          <OfferForm />
        </div>
      </section>

      <section
        id="covid-row4"
        className={`${styles.covidRow} ${styles.covidRow4}`}
      >
        <div className={styles.container}>
          <MarkdownContent
            className={`homecontent`}
            content={convertTokens(
              special.frontmatter.contentSection4,
              metadata
            )}
          />
        </div>
        <img
          id={styles.bricks}
          src="/images/bricks.svg"
          alt="Digital marketing for construction businesses"
        />
      </section>

      <section
        id="covid-row5"
        className={`${styles.covidRow} ${styles.covidRow5}`}
      >
        <div className={styles.container}>
          <MarkdownContent
            className={`homecontent`}
            content={convertTokens(
              special.frontmatter.contentSection5,
              metadata
            )}
          />
        </div>
      </section>

      <section
        id="covid-row6"
        className={`${styles.covidRow} ${styles.covidRow6}`}
      >
        <div className={styles.container}>
          <Link id={styles.covidLogoLink} to="/" title="OneBuilder - Home">
            <Image filename={`logo`} alt="OneBuilder" />
          </Link>
        </div>
      </section>
    </Layout>
  )
}
export const query = graphql`
  {
    special: markdownRemark(
      frontmatter: { title: { eq: "We Are Here To Help" } }
    ) {
      html
      frontmatter {
        title
        heroImage {
          childImageSharp {
            fluid(maxWidth: 1080) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        contentSection1
        contentSection2
        contentSection3
        contentSection4
        contentSection5
        meta {
          title
          description
          page_visible
        }
      }
    }
  }
`
